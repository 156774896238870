
var cart = [];


var form_check = ['vorname','Nachname','Strasse','Hausnr','Postleitzahl','Ort','Telefon','E-Mail'];

$(document).ready(function(){

  log("initialisiere PKN Script");




  refresh_global_cart();

  if( $('body').hasClass('warenkorb')) {

    create_cart(0);




  } 



});



$(function(){

    $('.cart_anz').keyup(function(){

        var id = $(this).attr('id');

        id = id.substring(9,id.length);
        log(id);

        basispreis = $('#cart_price_base_'+id).val();
        anz = $('#cart_anz_'+id).val();

        preis_neu = calc_preis(anz,basispreis);


        $('#price_'+id).html(preis_neu+" €");

    });



});




function calc_preis(anz,basis) {

        preis = parseInt(anz)*basis;

        log('**** log preis ****=');

        log(anz+" : "+basis);

        if(anz == 1) {
          preis = basis;
        }
        preis = parseFloat(preis);
        preis = preis.toFixed(2);

        


        return preis;


}



function add_to_cart(id) {
    console.clear();
    log("*****add_to_cart******");
    /*
    cart[id]['name'] = $('#cart_name_'+id).val();
    cart[id]['anz'] = $('#cart_anz_'+id).val();
    cart[id]['gewicht'] = $('#cart_gewicht_'+id).val();
    cart[id]['basispreis'] = $('#cart_price_base_'+id).val();
    */



    log("add "+id);
     
   


    //initialwert
    if(!getCookie('pkn_cart')) {

      log("neu");
      cart[id] = [id,$('#cart_name_'+id).val(),$('#cart_anz_'+id).val(),$('#cart_price_base_'+id).val(),$('#cart_gewicht_'+id).val(),$('#cart_p100_'+id).val()];
        
      temp = JSON.stringify(cart);
      

      setCookie('pkn_cart',temp,30);    
     
      



      //setCookie('pkn_cart',cart,30);    



    } else {

      log("cookie bereits gesetzt");

        cart = JSON.parse(getCookie('pkn_cart'));

        log(cart);


        cart[id] = [id,$('#cart_name_'+id).val(),$('#cart_anz_'+id).val(),$('#cart_price_base_'+id).val(),$('#cart_gewicht_'+id).val(),$('#cart_p100_'+id).val()];

        setCookie('pkn_cart',JSON.stringify(cart),30);    
    }

    
    $('#global_cart').addClass('shake');

    setTimeout(function(){
      $('#global_cart').removeClass('shake');

    },1000);


    refresh_global_cart();


}


function refresh_global_cart() {

    log("*****refresh_gloabl_cart******");
    anz = 0;

    if(getCookie('pkn_cart')) {


      cart = JSON.parse(getCookie('pkn_cart'));

      log(cart);
      temp = [];
      for (temp of cart) {
       
        if(temp != null) {
         anz += parseInt(temp[2]);
        }

       
      

      }
      
      
    
      


    }

    $('#global_cart span').html(anz);

}


function create_cart(s) {

    log("**** create cart ****");


    if(getCookie('pkn_cart')) {

      cart = JSON.parse(getCookie('pkn_cart'));

      f = JSON.stringify(cart);

      $('#cart_data').val(f);
      log(cart);

      html = "";

      html += '<div class="c1 firstline">';
        html += 'Produkt:';
      html += '</div>';

      html += '<div class="c2">';
        html += '';
      html += '</div>';


      html += '<div class="c3 firstline">';
        html += 'Anz.:';
      html += '</div>';


      html += '<div class="c4 firstline">';
        html += 'Einzelpreis:';
      html += '</div>';

      html += '<div class="c5 firstline">';
        html += 'Gesamtpreis:';
      html += '</div>';

      
      preis_gesamt = 0;
        for (temp of cart) {
          
          log("temp: "+temp);
          if(temp != null) {
            

            html += '<div class="c1">';
              html += temp[1]+"<br>"+"<span>Gewicht: "+temp[4]+" | Preis pro 100 g: "+transform_preis(temp[5])+" €</span>";
            html += '</div>';

            html += '<div class="c2">';
              if(s == 0) {
                html += '<a href="javascript:void(0);" onclick="plus_anz(\''+temp[0]+'\');">+</a><a href="javascript:void(0);" onclick="minus_anz(\''+temp[0]+'\');">-</a><a href="javascript:void(0);" onclick="del(\''+temp[0]+'\');">x</a>';
              }
              
            html += '</div>';


            html += '<div class="c3">';
              html += temp[2];
            html += '</div>';


            html += '<div class="c4">';
              html += transform_preis(temp[3])+" €";
            html += '</div>';

            html += '<div class="c5 ">';
            p = calc_preis(temp[2],temp[3]);
            html += transform_preis(p)+" €";
            
            
              t = p;
              preis_gesamt = preis_gesamt + parseFloat(t);
              log("Preis gesamt: "+preis_gesamt);
             
              $('#cart_val').val(transform_preis(preis_gesamt)+" €");
              
            html += '</div>';


          }

        
        

        }
      
      
      html += "<hr>";
      
      html += '<div class="gesamtpreis">Gesamtpreis: '+transform_preis(preis_gesamt)+' €<br><span>alle Preise inkl. MwSt.</span><br><br>';

      if( s == 0) {

        html += '<a href="javascript:void(0);" class="btn" onclick="stage(\'2\');">weiter</a>';

      }




      html += '</div>'; 
      if(s == 0) {
        $('#cart').html(html);
      } else {

        //['vorname','Nachname','Strasse','Hausnr','Postleitzahl','Ort','Telefon','E-Mail'];

        rd = "<h3>Rechnungsdaten:</h3>";

        rd += $("input[name=vorname]").val()+" "+$("input[name=Nachname]").val()+"<br>";
        rd += $("input[name=Strasse]").val()+" "+$("input[name=Strasse]").val()+"<br>";
        rd += $("input[name=Postleitzahl]").val()+" "+$("input[name=Ort]").val()+"<br><br>";
        rd += "Telefon: "+$("input[name=Telefon]").val()+"<br>E-Mail: "+$("input[name=E-Mail]").val()+"<br><br>";


        




        $('#zusammenfassung .inner').html("<h2>Zusammenfassung</h2><h3>Ihre Produkte:</h3>"+html+rd);
      }
      
      
       
      


    } else {

      $('#cart').html('Ihr Warenkorb ist leer');


    }


}


function transform_preis(preis) {

  log("**** transform Preis *****");
  preis = String(preis);
  log("Eingabe: "+preis);

  log("Punkt suchen: "+preis.search("\\."));

  aPreis = [];
  if(preis.search("\\.") == -1) {
    log("kein Punkt gefunden"); 

    aPreis[0] = preis;
    aPreis[1] = "0";

  } else {
    log("Punkt gefudnen");

    aPreis = preis.split('.');
  }

  
  log("Ausgabe "+aPreis);

  
 


  if(aPreis[1].length == 1) {
    preis = String(aPreis[0]+"."+aPreis[1]+"0");
  } 


  return  preis.replace(".",",");
  
}




function plus_anz(id) {

  cart = JSON.parse(getCookie('pkn_cart'));
  for (temp of cart) {

    
    

  }
  cart[id][2] = parseInt(cart[id][2])+1;
  setCookie('pkn_cart',JSON.stringify(cart),30);    
  create_cart(0);

}


function minus_anz(id) {

  cart = JSON.parse(getCookie('pkn_cart'));

  anz = parseInt(cart[id][2]);
  log("anz minus: "+anz);
  
    anz = cart[id][2]-1;
  if(anz >= 1) {
    cart[id][2] = anz;
    setCookie('pkn_cart',JSON.stringify(cart),30);    
    create_cart(0);
  } else {
    del(id);
  }
}


function del(id) {

  log('*** delete ***');
  log(id);
  cart = JSON.parse(getCookie('pkn_cart'));

  cart[id] = null;

  check = 0;

  for (temp of cart) {

   if(temp != null) {
     check++;
   }

  }

  if(check > 0) {
    setCookie('pkn_cart',JSON.stringify(cart),30);    
    log("lösche Cookie: nein");
  } else {
    log("erase cookie");
    eraseCookie('pkn_cart');
  }
  
  
  
  
  
  
  
  create_cart(0);

}
function eraseCookie(name) {
  setCookie(name, '', -1);
}




function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}



function setCookie(cname, cvalue, exdays) {
  /*
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  */

  var date = new Date();
  var minutes = 30;
  date.setTime(date.getTime() + (minutes * 60 * 1000));
  var expires = "expires="+date;

  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}








function stage(s) {


  if( s == 1 ) {
    /*
    $('#step2').addClass('cloak');
    $('#zusammenfassung').addClass('cloak');
    */
    $('html, body').animate({ scrollTop: ($('.ce_headline').offset().top-80)}, 2000);
  }

  if( s == 2) {

    $('#step2').removeClass('cloak');
    $('html, body').animate({ scrollTop: ($('#step2').offset().top)}, 2000);
  }
  if( s == 3) {


    var check = 0;

      for(i = 0; i <= form_check.length-1; i++) {

        if( $("input[name="+form_check[i]+"]").val() == "") {

          $("input[name="+form_check[i]+"]").addClass('error');

          check++;

        } else {
          $("input[name="+form_check[i]+"]").removeClass('error');
        }

      }

    

    if(check == 0) {
      create_cart(3);
      $('#zusammenfassung').removeClass('cloak');
      $('html, body').animate({ scrollTop: ($('#zusammenfassung').offset().top)}, 2000);
    }

  } 
 


}





function log(i) {
  console.log(i);
}